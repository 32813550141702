import {useEffect, useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import { addUser, GetUser, updateUser } from '../../../redux/actions/userActions'; 
import { USER_EDIT_RESET } from '../../../redux/constants/UserContants';
import { useHistory } from 'react-router-dom';
import { Circles } from 'react-loader-spinner'; 
import '../main.css'
import { toast } from 'react-toastify';

const AddUser = ({match}) => {
   const userId = match.params.id;
   const history = useHistory();
   const [loading, setLoading] = useState(false); 
   const [submitted, setSubmitted] = useState(false);  
   const [enablePassword, setEnablePassword] = useState(false)
   const dispatch = useDispatch();
   const [formState, setFormState] = useState({
      values: { 
         name: '',
         email: '',
         password: '',
         role: 'Member',
         isActive: true,
      },
      errors: {}
   });
   const [pageType, setPageType] = useState('')
   const location = useLocation();
   const user = useSelector((state) => state.userEdit.user);
   const organization = useSelector((state)=>state?.organizationDetails?.organization);
   const organizationId = organization?.organization_id;
	const role = useSelector((state) => state.userLogin.userInfo.data.role);

   useEffect(()=>{
      setFormState({
         values:{ 
            name: '',
            email: '',
            password: '',
            role: 'Member',
            isActive: true
         },
         errors: {}
      });
     if(location.pathname.includes('user/add')){
       setPageType("Add");
       setEnablePassword(true);
     } else if(location.pathname.includes('user/edit')){
      setPageType("Edit")
      setEnablePassword(false);
         if (!user?.name || user?.id !== Number(userId)) {
            if(role === 'Super_Admin'){
               dispatch(GetUser({id: userId, organizationId: '' })); 
            } else {
               dispatch(GetUser({id: userId, organizationId })); 
            }
             
         } else {
           setFormState({values:user, errors: {}})
         }
       //}
     }
   }, [location, user, userId])

   const handleChange = (e) => {
      const { name, value } = e.target;
      if(name === "name"){
         const regex = /^[a-zA-Z0-9\s]*$/;
			if(regex.test(value)){
            setFormState(prevState => ({
               values: {
                  ...prevState.values,
                  [name]: value
               },
               errors: {
                  ...prevState.errors,
                  [name]: getValidationError(name, value)
               }
            }));
         }
      } else {
         setFormState(prevState => ({
            values: {
               ...prevState.values,
               [name]: value
            },
            errors: {
               ...prevState.errors,
               [name]: getValidationError(name, value)
            }
         }));
      }
      
   };

   const getValidationError = (name, value) => {
      switch (name) {
         case 'email':
            return !validateEmail(value) ? 'Invalid email address' : '';
         case 'password':
            return !validatePassword(value) ? 'Password must be more than 6 characters' : '';
         default:
            return '';
      }
   };
      
   const validatePassword = (password) => {
      return password.length >= 6;
   };
    
   const validateEmail = (email) => {
      const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return re.test(String(email).toLowerCase());
   };
  
  	const handleRole = (e) => {
      setFormState(formState =>({
         ...formState,
         values:{
            ...formState.values,
            'role':
            e.target.type === 'checkbox'
               ? e.target.checked
               : e.target.value
         },    
      }));
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      const { name, email, role, password, isActive } = formState.values;
      console.log('Form is valid');
      setSubmitted(true); 

      if(pageType === 'Add'){
         if (name && email && role && password) {
            if (!formState.errors.email && formState.values.email) {
               if (!formState.errors.password && formState.values.password) {
                  if(password.length < 6 || password.length > 15) {
                     toast.error("Password must be between 6 and 15 characters")
                  } else {
                     setLoading(true)
                     dispatch(addUser({reqData: formState.values, organizationId})).then((response)=>{
                        setFormState({
                           values:{ 
                              name: '',
                              email: '',
                              password: '',
                              role: 'Member',
                              isActive: false
                           },
                           errors: {}
                        });
                        setSubmitted(false);
                        setLoading(false)
                        navigateToUserList();
                     });
                  }
               } else {
                  toast.error(formState.errors.password);
               }
            } else {
               toast.error(formState.errors.email)
            }
         } else if(!formState.values.name && !formState.values.email && !formState.values.password ) {
            toast.error("Fill all Details")
         }else if(!formState.values.name){
            toast.error("Enter Name")
         }else if(!formState.values.email){
            toast.error("Enter Email")
         }else if(!formState.values.password){
            toast.error("Enter Password")
         }
      } else if(pageType === 'Edit'){
         if (name && email && role) {

            if(enablePassword === true){
               if (!formState.errors.password && formState.values.password) {
                  if(password.length < 6 || password.length > 15) {
                     alert("Password must be between 6 and 15 characters")
                  } else {
                     setLoading(true)
                     let obj ={ 
                        id: Number(userId),
                        name: name,
                        role: role,
                        isActive: isActive,
                        password: password
                     }

                     let orgId = '';
                     if(role === "Super_Admin"){
                        orgId = '';
                     } else {
                        orgId = organizationId;
                     }
      
                     dispatch(updateUser({reqData: obj, orgId})).then((response)=>{
                        setFormState({
                           values:{ 
                              name: '',
                              email: '',
                              password: '',
                              role: 'Member',
                              isActive: false
                           },
                           errors: {}
                        });
                        setSubmitted(false);
                        setLoading(false)
                        navigateToUserList();
                     });
                  }
               } else {
                  alert(formState.errors.password)
               }
            } else {
               setLoading(true)
               let obj ={ 
                  id: Number(userId),
                  name: name,
                  role: role,
                  isActive: isActive
               }
                     let orgId = '';
                     if(role === "Super_Admin"){
                        orgId = '';
                     } else {
                        orgId = organizationId;
                     }

               dispatch(updateUser({reqData: obj, orgId})).then((response)=>{
                  setFormState({
                     values:{ 
                        name: '',
                        email: '',
                        password: '',
                        role: 'Member',
                        isActive: false
                     },
                     errors: {}
                  });
                  setSubmitted(false);
                  setLoading(false)
                  navigateToUserList();
               });
            }
               
         } else {
            toast.error("Fill all details....")
         }
      }
     
   }

	const navigateToUserList = () => {
      dispatch({ type: USER_EDIT_RESET });
		history.push('/users');
	};

   const handleToggleChange = () => {
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          isActive: !formState.values.isActive // Toggle the isActive value
        }
      });
    };

	return(
		<>
		   <div className="container-scroller">
				<Header/>
				<div className="container-fluid page-body-wrapper">
				   <Sidebar/>
				   <div className="main-panel">
				        <div className="content-wrapper">
					        <div className="row">
				               <div className="col-12 grid-margin">
				                  <div className="card">
				                     <div className="card-body">
				                        {/* <h4 className="card-title">{pageType === 'Add' ? "Add User" : "Edit User" }</h4> */}
                                    <div className="header headingWrap">
                                         <Link to="/users" className ="backarrowcolor">
                                             <i class="bi bi-arrow-left-circle-fill backIcon"></i>
                                          </Link>
                                         <h4 className="card-title">{pageType === 'Add' ? "Add User" : "Edit User" } </h4>
                                         </div>	
				                        <form className="form-sample formStyle mt-4" onSubmit={handleSubmit}>
				                           <p className="card-description">				                              
				                           </p>
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Name
                                                      <span style={{ color: 'red' }}>*</span>
                                                    </label>
				                                    <div className="">
									                        <input type="text" className={'form-control form-control-lg' + (submitted && !formState.values.name ? ' is-invalid' : '')} 
					                                        name="name"                                
					                                        onChange={handleChange}
					                                        value={formState.values.name || ''}
                                                       
					                                        />
					                                        {submitted && !formState.values.name &&
					                                            <div className="inline-errormsg">Name is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>

                                          <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Email
                                                       <span style={{ color: 'red' }}>*</span>
                                                    </label>
				                                    <div className="">
                                                    <input
                                                      type="text"
                                                       className={'form-control form-control-lg' + (submitted && !formState.values.email ? ' is-invalid' : '')}
                                                       name="email"
                                                       onChange={handleChange}
                                                       value={formState.values.email || ''}
                                                       disabled = {pageType === 'Add' ? false : true}
                                                    />
                                                    {submitted && !formState.values.email && (
                                                       <div className="invalid-feedback">Email is required</div>
                                                    )}
                                                    {submitted && formState.errors.email && (
                                                       <div className="invalid-feedback">{formState.errors.email}</div>
                                                    )}
				                                    </div>
				                                 </div>
				                              </div>	

                                          <div className="col-md-6">
                                             <div className="form-group position-relative">
                                                   <label className="col-form-label required" required>Password
                                                   {pageType === 'Add' ? <span style={{ color: 'red' }}>*</span> : null }
                                                   </label>
                                             <div className="">
                                                <div className="">
                                                   <input type="text" className={'form-control form-control-lg' + (submitted && !formState.values.password ? ' is-invalid' : '')} 
                                                      name="password"                          
                                                      onChange={handleChange}
                                                      value={formState.values.password || ''}
                                                      disabled = {!enablePassword}
                                                      />
                                                      
                                                      {submitted && !formState.values.password && enablePassword === true &&
                                                         <div className="inline-errormsg">Password is required</div>
                                                      }
                                                </div>
                                                {pageType === 'Edit' ?
                                                <div className="formEditIcon">
                                                {enablePassword ?
                                                   <i className="bi bi-x closeicon" onClick={()=> {
                                                      setEnablePassword(false)
                                                      setFormState(formState =>({
                                                         ...formState,
                                                         values:{
                                                            ...formState.values,
                                                            'password': ''
                                                         },    
                                                      }));
                                                   }
                                                   }></i>
                                                :
                                                   <i className="bi bi-pencil-square" onClick={()=> {setEnablePassword(true)}}></i>
                                                }
                                                </div>
                                                :null }
                                                 </div>
                                             </div>
                                          </div>

                                          <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Role
                                                    <span style={{ color: 'red' }}>*</span>
                                                    </label>
				                                    <div className="">
				                                       <select className="form-control" name="role"  onChange={handleRole} value={formState.values.role}>
				                                          <option value="Member">Member</option>
				                                          <option value="Client">Client</option>
                                                      <option value="Tester">Tester</option>
				                                       </select>
				                                    </div>
				                                 </div>
				                              </div>

                                          <div className="col-md-6">
                                             <div className="form-group ">
                                                <div className='d-flex'>
                                                <label className="col-form-label mr-3">
                                                   Active  <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <div className="">
                                                   <label className="switch">
                                                      <input
                                                         type="checkbox"
                                                         checked={formState.values.isActive}
                                                         onChange={handleToggleChange}
                                                      />
                                                      <span className="slider round"></span>
                                                   </label>
                                                </div>
                                                </div>
                                             </div>
                                          </div>

				                           </div>

				                            <div className="text-left">
				                            	<button type="submit" className="Button col-sm-2 col-form-label text-center mr-2">Submit</button>
                    							<button className="ButtonLight col-sm-2 col-form-label text-center ml-2" onClick={()=> navigateToUserList()}>Cancel</button>
                    						</div>
				                        </form>
				                     </div>
				                  </div>
				               </div>
				            </div>
			            </div>
				       <Footer/>
				    </div>
				</div>
			</div>
         {loading &&  
            <div className="fullscreen-loader">
              <Circles color="#F1B621" height={80} width={80} />
            </div>
         }
		</>
		)
}

export default AddUser;
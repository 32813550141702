import {
    ORGANIZATION_CREATE_FAIL,
    ORGANIZATION_CREATE_REQUEST,
    ORGANIZATION_CREATE_SUCCESS,
    ORGANIZATION_DELETE_FAIL,
    ORGANIZATION_DELETE_REQUEST,
    ORGANIZATION_DELETE_SUCCESS,
    ORGANIZATION_DETAILS_SUCCESS,
    ORGANIZATION_EDIT_FAIL,
    ORGANIZATION_EDIT_REQUEST,
    ORGANIZATION_EDIT_SUCCESS,
    ORGANIZATION_LIST_FAIL,
    ORGANIZATION_LIST_REQUEST,
    ORGANIZATION_LIST_SUCCESS,
    ORGANIZATION_UPDATE_FAIL,
    ORGANIZATION_UPDATE_REQUEST,
    ORGANIZATION_UPDATE_SUCCESS,
  } from "../constants/OrganizationConstants";
  import axios from "axios";
  import { logout } from "./userActions";
  import { toast } from "react-toastify";
  import {ToastObjects} from "./toastObject";

  export const Organizationlist = (pageNum, organizationsPerPage, sortBy, searchText, role,id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORGANIZATION_LIST_REQUEST });

      let url = "";

      if(role === "Super_Admin"){
        url = `admin/list/organizations?page=${pageNum}&limit=${organizationsPerPage}&sortBy=${sortBy}&searchText=${searchText}`
      } else {
        url = "user/list/organizations"
      }
      let responseData = await axios.get(`${url}`);
      const data = responseData.data;

      data['sortBy'] = sortBy;
      data['searchText'] = searchText;
      data['numOfPages'] = Math.ceil(data.totalCount / organizationsPerPage);
      dispatch({ type: ORGANIZATION_LIST_SUCCESS, payload: data });
      return data?.data;

    } catch (error) {
      const message = error.message;
      if (message === "Authentication failed: Invalid token." || message === "Unauthorized User" || message === "Request failed with status code 401") {
        dispatch(logout());
      }
      if(message === "Request failed with status code 401"){
        dispatch(logout());
      }else{
        toast.error(message);
      }
      dispatch({
        type: ORGANIZATION_LIST_FAIL,
        payload: message,
      });
    }
  };
    // // Create Organization
    export const createOrganization = (reqData) => async (dispatch, getState) => {
      try {
        dispatch({ type: ORGANIZATION_CREATE_REQUEST });
  
        const response = await axios.post(
          `user/add/organization`,
          reqData
        );
  
        const responseData = response.data;
        if (!responseData.status) {
          toast.error(responseData.message, ToastObjects);  
        }else{
          toast.success(responseData.message, ToastObjects);        
          dispatch({ type: ORGANIZATION_CREATE_SUCCESS, payload: responseData.data });  
        }
      } catch (error) {
        const message = error.message;
        if (message === "Authentication failed: Invalid token." || message === "Unauthorized User" || message === "Request failed with status code 401") {
          dispatch(logout());
        }

        if(message === "Request failed with status code 401"){
          dispatch(logout());
        }else{
          toast.error(message, ToastObjects);
        }

        dispatch({
          type: ORGANIZATION_CREATE_FAIL,
          payload: message,
        });
      }
    };

    export const getOrganization = (id) => async (dispatch) => {
      try {
        dispatch({ type: ORGANIZATION_EDIT_REQUEST });
        const { data } = await axios.get(`users/organization/${id}`);
        dispatch({ type: ORGANIZATION_EDIT_SUCCESS, payload: data });
      } catch (error) {
        const message =error.message;
        if (message === "Authentication failed: Invalid token."  || message ==="Unauthorized User" || message === "Request failed with status code 401") {
          dispatch(logout());
        }

        if(message === "Request failed with status code 401"){
          toast.error("Teken Expired")
        }else{
          toast.error(message, ToastObjects);
        }

        dispatch({
          type: ORGANIZATION_EDIT_FAIL,
          payload: message,
        });
      }
    };
  
  // Delete organization
//   export const deleteorganization = (id) => async (dispatch, getState) => {
  
//     try {
//       dispatch({ type: ORGANIZATION_DELETE_REQUEST });
  
//       const response = await axios.delete(`users/organization/${id}`);
  
//       const responseData = response.data;
  
//       if (!responseData.success) {
//           toast.error(responseData.message, ToastObjects);  
//         }else{
//           toast.success(responseData.message, ToastObjects);  
//           dispatch({ type: ORGANIZATION_DELETE_SUCCESS });
//       }
//     } catch (error) {
//       const message = error.message;
//     if (message === "Authentication failed: Invalid token." || message === "Unauthorized User" || message === "Request failed with status code 401") {
//       dispatch(logout());
//     }
//       if(message === "Request failed with status code 401"){
//         toast.error("Token Expired");
//       }else{
//         toast.error(message, ToastObjects);
//       }
//       dispatch({
//         type: ORGANIZATION_DELETE_FAIL,
//         payload: message,
//       });
//     }
//   };
  
  // Create organization
//   export const createorganization = (reqData) => async (dispatch, getState) => {
      
//       try {
//         dispatch({ type: ORGANIZATION_CREATE_REQUEST });
  
//         const response = await axios.post(
//           `users/organization`,
//           reqData
//         );
  
//         const responseData = response.data;
//         if (!responseData.status) {
//           toast.error(responseData.message, ToastObjects);  
//         }else{
//           toast.success(responseData.message, ToastObjects);        
//           dispatch({ type: ORGANIZATION_CREATE_SUCCESS, payload: responseData.data });  
//         }
        
        
//       } catch (error) {
//         const message = error.message;
//       if (message === "Authentication failed: Invalid token." || message === "Unauthorized User" || message === "Request failed with status code 401") {
//         dispatch(logout());
//       }
//         if(message === "Request failed with status code 401"){
//           toast.error("Token Expired")
//         }else{
//           toast.error(message, ToastObjects);
//         }
//         dispatch({
//           type: ORGANIZATION_CREATE_FAIL,
//           payload: message,
//         });
//       }
//     };
  
  // Edit organization
//   export const editorganization = (id) => async (dispatch) => {
//     try {
//       dispatch({ type: ORGANIZATION_EDIT_REQUEST });
//       const { data } = await axios.get(`users/organization/${id}`);
//       dispatch({ type: ORGANIZATION_EDIT_SUCCESS, payload: data });
//     } catch (error) {
//       const message =error.message;
//       if (message === "Authentication failed: Invalid token."  || message ==="Unauthorized User" || message === "Request failed with status code 401") {
//         dispatch(logout());
//       }
//       if(message === "Request failed with status code 401"){
//         toast.error("Teken Expired")
//       }else{
//         toast.error(message, ToastObjects);
//       }
//       dispatch({
//         type: ORGANIZATION_EDIT_FAIL,
//         payload: message,
//       });
//     }
//   };
  
  // Update organization
  export const updateorganizationStatus = (reqData) => async (dispatch, getState) => {
    try {
      const { data } = await axios.patch(
        `/admin/organization`,
        reqData      
      );
  
    } catch (error) {
      const message = error.message;
      if (message === "Authentication failed: Invalid token." || message=== "Unauthorized User" || message === "Request failed with status code 401") {
        dispatch(logout());
      }

      if(message === "Request failed with status code 401"){
        dispatch(logout());
      }else{
        toast.error(message, ToastObjects);
      }
    }
  };
  